//import Contact from "../data/Contact";
import react,{ useContext,useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../context/homecontext";
import axios from "axios";
function Header({size,cart,setCart,userId}){
//const [price,setPrice] = useState(0);
const [submenu,setSubMenu] = useState([]);
const {isLoading,contact,logoData,category} = useContext(AppContext);
//console.log(cart);
const {c_number,email,work_hour} = contact;
const {logo,tag_name} = logoData;

const handleRemove = (id) =>{
const arr = cart.filter((item)=>item.product_id !== id)
setCart(arr);
handlePrice();
}
const handleChange = (product_id,d=1) =>{
let ind = 0;
console.log(product_id);
cart.forEach((data,index)=>{
if(data.product_id === product_id)
ind = index;
});
const tempArr = cart;
tempArr[ind].amount += d;
if(tempArr[ind].amount === 0)
tempArr[ind].amount = 0;
setCart([...tempArr]);
handlePrice();
}
const handlePrice = () => {
let ans = 0;
cart.map((item) => {
ans += item.amount * parseFloat(item.product_price);
})
return ans;
}

const fetchsubCategory = async() =>{    
    try{
      const res =  await axios.get("https://breaddough.kdassociate.us/api/productsubcategory/");
      const categoryData = await res.data;
      setSubMenu(categoryData);
      
    }catch(error){
        console.log("subcategory error",+error); 
    }
}
useEffect(()=>{
    fetchsubCategory();
},[])
return(
<div>
    <div className="mobile-menu">
        <nav className="mobile-header">
            <div className="header-logo">
                <a href="/">
                    <img src={logo} alt={tag_name} />
                </a>
            </div>
            <div className="header-bar">
                <span />
                <span />
                <span />
            </div>
        </nav>
        <nav className="mobile-menu">
            <div className="mobile-menu-area">
                <div className="mobile-menu-area-inner">
                    <ul>
                        <li className="active">
                            <Link to="/">Home</Link>
                        </li>

                        <li>
                            <Link to="/about-us">About Us</Link>
                        </li>

                        <li>
                            <Link to="/menumob">Menu</Link>
                           
                        </li>
                        
                        <li>
                            <Link to="/contact-us">Contact Us</Link>
                        </li>
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                        <li>
                            <Link to="/signup">Sign Up</Link>
                        </li>

                    </ul>
                </div>
            </div>
        </nav>
    </div>

    <header className="header-section">
        <div className="header-area">
            <div className="header-top">
                <div className="container">
                    <div className="top">
                        <ul className="left">
                            <li>
                                <i className="fas fa-phone" />{" "}
                                <a href="tel:{c_number}" target="_blank" style={{ color: "#fff" }}>
                                    {" "}
                                    {c_number}
                                </a>
                            </li>
                            <li>
                                <i className="fa-regular fa-envelope" />{" "}
                                <a href="mailto:{email}
                " target="_blank" style={{ color: "#fff" }}>
                                    {email}
                                </a>
                            </li>
                            <li>
                                <i className="fa-regular fa-clock" />
                                &nbsp; {work_hour}
                            </li>
                        </ul>
                        <ul className="right">
                            <li>
                                <a href="https://www.facebook.com/people/Breaddough-Hotfresh/100089824064774/">
                                    <i className="fab fa-facebook-f" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/breaddough_hotfresh/">
                                    <i className="fab fa-instagram" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="header-bottom">
                <div className="container">
                    <div className="primary-menu">
                        <div className="logo">
                            <a href="/">
                                <img src={logo} alt={tag_name} />
                            </a>
                        </div>
                        <div className="main-area">
                            <div className="main-menu">

                                <nav className="navbar navbar-expand-lg navbar-light">
                                    {/* Container wrapper */}
                                    <div className="container-fluid">
                                        {/* Toggle button */}
                                        <button className="navbar-toggler px-0" type="button" data-mdb-toggle="collapse"
                                            data-mdb-target="#navbarExampleOnHover" aria-controls="navbarExampleOnHover"
                                            aria-expanded="false" aria-label="Toggle navigation">
                                            <i className="fas fa-bars" />
                                        </button>
                                        {/* Collapsible wrapper */}
                                        <div className="collapse navbar-collapse" id="navbarExampleOnHover">
                                            {/* Left links */}
                                            <ul className="navbar-nav me-auto ps-lg-0" style={{ paddingLeft: "0.15rem"
                                                }}>
                                                <li className="active">
                                                    <Link to="/">Home</Link>
                                                </li>

                                                <li>
                                                    <Link to="/about-us">About Us</Link>
                                                </li>

                                                <li className="nav-item dropdown dropdown-hover position-static">
                                                    <a className="nav-link dropdown-toggle" href="/shoap"
                                                        id="navbarDropdown" role="button" data-mdb-toggle="dropdown"
                                                        aria-expanded="false">
                                                        Menu
                                                    </a>
                                                    {/* Dropdown menu */}
                                                    <div className="dropdown-menu w-100 mt-0"
                                                        aria-labelledby="navbarDropdown" style={{ borderTopLeftRadius:
                                                        0, borderTopRightRadius: 0 }}>
                                                        <div className="container">
                                                            <div className="row">
            {category && category.map((cat)=>{
                    const {cat_id,cname,slug} = cat;
                    const product = submenu.filter((abc)=>{
                        return abc.cat_id === cat.cat_id;
                    });    
                            return(               
                                    
                                <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                                    <div className="list-group list-group-flush">
                                    <Link to={"/shoap/"+slug}> 
                                        <p
                                            class="mb-0 list-group-item text-uppercase font-weight-bold">
                                            {cname}
                                        </p>
                                        </Link>
                        {product && product.map((data)=>{
                         const {sname,sub_slug,banner} = data;
                         let url = 'assets/images/wraps.jpg';
                         if(banner !== null){
                            url = banner; 
                         }
                           return(            
                            <Link to={"/shoap/"+slug+"/"+sub_slug} class="list-group-item list-group-item-action">     
                                        
                                            <img src={url} alt="" />
                                            {data.sname}
                            </Link>                
                                                                             
                            )
                        })}            
                                    </div>
                                </div>
                                )
                            })}
                        

                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>

                                                <li>
                                                    <Link to="/blog">Blog</Link>
                                                </li>

                                                <li>
                                                    <Link to="/contact-us">Contact Us</Link>
                                                </li>
                                            </ul>
                                            {/* Left links */}
                                        </div>
                                        {/* Collapsible wrapper */}
                                    </div>
                                    {/* Container wrapper */}
                                </nav>

                            </div>

                        </div>



                        <div className="cart-search">
                            <ul>

                                <li className="cart-area">
                                    <div className="cart-icon">
                                        <i className="icofont-cart-alt" />
                                    </div>
                                    <div className="count-item">{size}</div>
                                    <div className="cart-content">
                                        <div className="cart-title">
                                            <div className="add-item">{size} Items Added</div>
                                            <div className="list-close">
                                                <a href="#">Close</a>
                                            </div>
                                        </div>
                                        <div className="cart-scr scrollbar">
                                            <div className="cart-con-item">
                                                {cart && cart.map((data)=>{
                                                const
                                                {product_id,product_code,title,product_price,amount,photo,cname,sname}
                                                =
                                                data;
                                                // p = p + parseFloat(product_price);
                                                return(
                                                <div className="cart-item">
                                                    <div className="cart-inner">
                                                        <div className="cart-top">
                                                            <div className="thumb">
                                                                <a href="#">
                                                                    <img src={photo} alt={title} />
                                                                </a>
                                                            </div>
                                                            <div className="content">
                                                                <a href="#">{title}</a>
                                                            </div>
                                                            <div className="remove-btn">
                                                                <a href="javascript:void(0)"
                                                                    onClick={()=>handleRemove(product_id)}>
                                                                    <i className="icofont-close" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="cart-bottom">
                                                            <div className="sing-price">Tk. {product_price}</div>
                                                            <div className="cart-plus-minus">
                                                                <div className="dec qtybutton" onClick={()=>
                                                                    handleChange(product_id,-1)}>-</div>
                                                                <div className="dec qtybutton" onClick={()=>
                                                                    handleChange(product_id,-1)}>-</div>
                                                                {amount}
                                                                <div className="inc qtybutton" onClick={()=>
                                                                    handleChange(product_id,+1)}>+</div>
                                                                <div className="inc qtybutton" onClick={()=>
                                                                    handleChange(product_id,+1)}>+</div>
                                                            </div>
                                                            <div className="total-price">Tk.
                                                                {amount*parseFloat(product_price)}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                )
                                                })}






                                            </div>
                                        </div>
                                        <div className="cart-scr-bottom">
                                            <ul>
                                                <li>
                                                    <div className="title">Subtotal</div>
                                                    <div className="price">Tk. {handlePrice()}</div>
                                                </li>
                                                <li>
                                                    <div className="title">Shipping</div>
                                                    <div className="price">Tk.0.00</div>
                                                </li>
                                                <li>
                                                    <div className="title">Cart Total</div>
                                                    <div className="price">Tk. {handlePrice()}</div>
                                                </li>
                                            </ul>
                                            {size > 0 &&

                                            <div className="list-close">
                                                <a href="#">
                                                    <Link to="/checkout" className="food-btn">
                                                    <span>Place Order</span>
                                                    </Link>

                                                    <Link to="/cart" className="food-btn">
                                                    <span>Cart</span>
                                                    </Link>
                                                </a>
                                            </div>

                                            }
                                        </div>
                                    </div>
                                </li>
                                {userId === 0 &&
                                <li className="login">
                                    <Link to="/login"><a href="#" class="food-btn style-2">Login/Register</a></Link>

                                </li>
                                }
                                {userId > 0 &&
                                <>
                                    <li className="login">
                                        <Link to="/myaccount"><i className="icofont-ui-user" /></Link>

                                    </li>
                                    <li className="login">
                                        <Link to="/logout"><i class="fa-solid fa-arrow-right-from-bracket"></i></Link>

                                    </li>
                                </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    </header>
</div>

)
}

export default Header;