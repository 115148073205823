import { useState } from "react";
import { Link } from "react-router-dom/dist";
import CartSlider from "./CartSlider";
import { Helmet } from "react-helmet";
function Cart({cart,setCart}){    
    const handleRemove = (id,rate) =>{
        const arr = cart.filter((item)=>item.product_id !== id)
            setCart(arr);
            handlePrice();
    }
const handleChange = (product_id,d=1) =>{ 
        let ind = 0;
        console.log(product_id);
        cart.forEach((data,index)=>{
            if(data.product_id === product_id)
            ind = index;
        });         
        const tempArr = cart;
        tempArr[ind].amount += d;
        if(tempArr[ind].amount === 0)
            tempArr[ind].amount = 0;
          setCart([...tempArr]);
          handlePrice();
    }
    const handlePrice = () => {
        let ans = 0;
        cart.map((item) => {
           ans += item.amount * parseFloat(item.product_price);
         })
        return ans;
      }    
let count =0;    
    return(
        <>
         <Helmet>       
        <title>Cart</title>
        <meta name='description' content="Cart" />
        <meta name="keywords" content="Cart"></meta>      
       </Helmet>
        <CartSlider/>
        <div class="shop-cart padding-tb">
    <div class="container df-x">

        <div class="col-md-8 col-cst">
            <div class="section-wrapper">
                <div class="cart-top">
                    <table>
                        <thead>
                            <tr>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cart && cart.map((data)=>{
                            const {product_id,product_code,title,product_price,amount,photo,cname,sname} = data;
                            count += 1;
                            return(
                            <tr>
                                <td class="product-item">
                                    <div class="p-thumb">
                                        <a href="#"><img src={photo} alt={title} /></a>
                                    </div>
                                    <div class="p-content">
                                        <a href="#">{title}</a>
                                    </div>
                                </td>
                                <td>${product_price}</td>
                                <td>
                                    <div class="cart-plus-minus">
                                        <button onClick={()=>handleChange(product_id,-1)}>-</button>
                                        {amount}
                                        <button onClick={()=>handleChange(product_id,+1)}>+</button>

                                    </div>
                                </td>
                                <td>${amount*parseFloat(product_price)}</td>
                                <td>
                                    <a href="javascript:void(0)"
                                        onClick={()=>handleRemove(product_id,amount*parseFloat(product_price))}><img
                                            src="assets/images/shop/del.png" alt="product" /></a>
                                </td>
                            </tr>

                            )
                            })}


                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <div class="col-md-4 col-cst">
            <div class="section-wrapper">

                <div class="cart-bottom">

                    <div class="shiping-box">
                        <div class="row">

                            <div>
                                <div class="cart-overview">
                                    <h4>Cart Totals</h4>
                                    <ul>
                                        <li>
                                            <span class="pull-left">Cart Subtotal</span>
                                            <p class="pull-right">${handlePrice()}</p>
                                        </li>
                                        <li>
                                            <span class="pull-left">Shipping and Handling</span>
                                            <p class="pull-right">Free Shipping: 00.00</p>
                                        </li>
                                        <li>
                                            <span class="pull-left">Order Total</span>
                                            <p class="pull-right">$ {handlePrice()}</p>
                                        </li>
                                        <li>
                                            {count && count > 0 &&
                                            <Link to="/checkout" class="food-btn"><span>Order Now</span></Link>

                                            }
                                            <Link to="/shoap" class="food-btn"><span>Add More</span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    </div>
</div>
   </>
    )
}

export default Cart;