import { useContext,useEffect } from "react";
import { Link } from "react-router-dom";

function Pastry({status}){

return(
<>
    <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>Puff Pastry</h3>
                <ul class="breadcrumb">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>Puff Pastry</li>
                </ul>
            </div>
        </div>
    </section>

    <>
        <div className="container pf-mt" >

            <div class="row">
            <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div className="card bgcd  mt-4">
                        <img src="https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg" className="card-img-top"
                            alt="..." />
                        <div className="card-body">
                            <div className="text-section">
                                <h5 className="card-title fw-bold">JALEBI</h5>
                                <div>$13.99</div>
                               
                            </div>


                            <div className="cta-section">
                               <p className="card-text">
                               At "BreadDough Hot & Fresh," nestled in the heart of Rochester, New York, we take immense pride in crafting the most authentic Afghan Jalebi. Our Jalebi is a labor of love, prepared meticulously by our skilled bakers who have honed their craft to perfection.
                                </p>
                                <a href="#" className="btn btn-dark">
                                    Add to Cart
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
             
        
                
        
            </div>
        </div>

    </>



</>
)
}

export default Pastry;