import { useContext,useEffect } from "react";
import { Link } from "react-router-dom";

function Menumob({status}){

return(
<>
    <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>Menu</h3>
                <ul class="breadcrumb">
                    <li>
                        <Link to="/">Home</Link>
                    </li>
                    <li>Menu</li>
                </ul>
            </div>
        </div>
    </section>

    <div className="container mbmnu">
        <div className="row">
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="list-group list-group-flush">
                    <p class="mb-0 list-group-item text-uppercase font-weight-bold">
                        Restaurant
                    </p>
                    <a href="" class="list-group-item list-group-item-action">
                        <img src="assets/images/wraps.jpg" alt="" />
                        Wraps</a>
                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/veg.jpg"
                            alt="" />Vegetarian</a>
                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/meat.jpg"
                            alt="" />Meaty</a>
                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/kabobs.jpg"
                            alt="" />Kabobs</a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-lg-0">
                <div className="list-group list-group-flush">
                    <p class="mb-0 list-group-item text-uppercase font-weight-bold">
                        Bakery
                    </p>
                    <a href="/shoap/bread" class="list-group-item list-group-item-action"><img
                            src="assets/images/bread.jpg" alt="" />Bread</a>
                    <a href="/shoap/cookies" class="list-group-item list-group-item-action"><img
                            src="assets/images/cookies.jpg" alt="" />Cookies</a>
                    <a href="/shoap/cake" class="list-group-item list-group-item-action"><img
                            src="assets/images/cake.jpg" alt="" />Cakes</a>
                    <a href="/shoap/puff-pastry" class="list-group-item list-group-item-action"><img
                            src="assets/images/puff-pastry.jpg" alt="" />Puff Pastry

                    </a>
                </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-3 mb-md-0">
                <div className="list-group list-group-flush">
                    <p class="mb-0 list-group-item text-uppercase font-weight-bold">
                        Beverages
                    </p>
                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/soda.jpg"
                            alt="" />Soda</a>
                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/energy.jpg"
                            alt="" />Energy Drinks</a>

                    <a href="" class="list-group-item list-group-item-action"><img src="assets/images/smoothies.jpg"
                            alt="" />Smoothies</a>





                </div>
            </div>

        </div>
    </div>


</>
)
}

export default Menumob;