
import {useNavigate} from 'react-router-dom';
function LogOut({setUserId,userId}){
    setUserId(0); 
    const history = useNavigate();
    const callPage = () =>{
        history('/login');
    } 
    if(userId === 0){ callPage() ;}
       
}

export default LogOut;