
import { useContext, useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/homecontext";
import Title from "../../pages/Title";
const p_slug = 'product';
function ShoapList({handleClick}){
    const [currentPage,setCurrentPage] = useState(1);
    const {isLoading,products,page,fetchPage} = useContext(AppContext);
    const {page_name,meta_title,meta_keyword,meta_description} = page;
    const recordsPerPage = 8;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex-recordsPerPage;
    const records = products.slice(firstIndex,lastIndex);
    const nPage = Math.ceil(products.length/recordsPerPage);
    const numbers = [...Array(nPage+1).keys()].slice(1); 
    useEffect(() =>{
        fetchPage(`https://breaddough.kdassociate.us/api/pagelist/${p_slug}`);
    },[])
    //console.log(products);
    if(isLoading){
        return(
            <div>Loading.............</div>
        )
    }

    const changeCPage = (n) =>{
        setCurrentPage(n);
     } 
     const prePage = () =>{
        //window.alert('hello');
         if(currentPage !== 1){
            setCurrentPage(currentPage-1);
         }
     } 
     const nextPage = () =>{
       // window.alert('hello');
        if(currentPage !== nPage){
            setCurrentPage(currentPage+1);
        }
     }  
    return(
        <>
        <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description} />
        <section class="page-header style-2">
            <div class="container">
                <div class="page-title text-center">
                    <h3>{page_name}

        </h3>
                    <ul class="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>{page_name}</li>
                    </ul>
                </div>
            </div>
        </section>
        
        <div className="shop-page single padding-tb bg-fa">
            <div className="container">
                <div className="section-wrapper">
                    <div className="shop-title d-flex flex-wrap justify-content-between">
                        <p class="pd-tl">
                            Shop All Products
                        </p>
                        <p>{products.length} Results</p>
    
    
                    </div>
    
                    <div className="shop-product-wrap grid row">
                        {records && records.map((product)=>{
                        //const {product_id,product_code,title,product_price,amount,photo,cname,sname} = product;
                        return(
                            <div className="col-xl-3 col-md-6 col-12 scl" >
                            <div className="product-item">
                                <div className="product-thumb">
                                    <img className="prd-main-img" src={product.photo} alt={product.title}/>
                                    <span className="price">${product.product_price}</span>
                                    <div className="product-action-link">
                                        <Link to={"/shoaps/"+product.product_code} data-rel="lightcase"><i className="icofont-eye"></i></Link>
                                   
                                        <a href="javascript:void(0)" onClick={() => handleClick(product, 1)}><i className="icofont-cart-alt"></i></a>
                                    </div>
                                </div>
                                <div className="product-content">
                                    <div className="product-title">
                                        <span className="cat-name">{product.cname}</span>
                                        <h6><Link to={"/shoaps/"+product.product_code}>{product.title}</Link></h6>
                                      

                                        <a href="javascript:void(0)" class="food-btn style-2 atc-btn btn-pd"><span>Order Now</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                        })}
    
    
    
                    </div>
                    <div className="paginations">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-2 btn-pag">
                                    <a href="#" class="food-btn" onClick={prePage}><span><i
                                                class=""></i>&nbsp;&nbsp; Previous</span> </a>
                                </div>
                                <div class="col-md-8">
                                    <ul className="d-flex flex-wrap justify-content-center">
                                        {
                                        numbers.map((n,i)=>(
                                        <li key={i}>
                                            {(currentPage === n) ? <a href="#" onClick={()=>changeCPage(n)}
                                                style={{"color":"red"}}>{n}</a>:<a href="#"
                                                onClick={()=>changeCPage(n)}>{n}</a> }
    
                                        </li>
                                        ))
                                        }
    
    
                                    </ul>
                                </div>
                                <div class="col-md-2 btn-pag tn-pag"> <a href="#" class="food-btn"
                                        onClick={nextPage}><span>Next&nbsp;&nbsp; <i
                                                class="fa-solid fa-arrow-right"></i></span></a>
    
                                </div>
                            </div>
                        </div>
    
    
                    </div>
                </div>
            </div>
        </div>
    </>
    )
 
}
export default ShoapList;