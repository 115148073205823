const HomeReducer = (state,action) =>{
      switch(action.type){
        case "API_LOADING" :
            return{
                ...state,
                isLoading: true,
            };
        case "API_ERROR":
         return{  
             ...state,
             isLoading:false,
             isError:true,
        };
        case "GET_CONTACT_DATA" :
            return{
                ...state,
                isLoading:false,
                contact: action.payload,    
            };
        case "SET_LOGO":
            return{
                ...state,
                isLoading:false,
                logoData:action.payload,
            };
        case "SET_PRODUCT":
            return{
                ...state,
                isLoading:false,
                products:action.payload,
            };
        case "SET_CATEGORY":
            return{
                ...state,
                isLoading:false,
                category:action.payload,
            };
         case "SET_BLOG":
            return{
                ...state,
                isLoading:false,
                blog:action.payload,
            };
         case "SET_ABOUT":
            return{
                ...state,
                isLoading:false,
                about:action.payload,
            }; 
          case "SET_PAGE":
            return{
                ...state,
                isLoading:false,
                page:action.payload,
            };                      
        default :
        return{
            ...state,
        };
      }
}
export default HomeReducer;