import { Link } from "react-router-dom";
function CheckoutSlider(){
    return(
        <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>Checkout</h3>
                <ul class="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>Checkout</li>
                </ul>
            </div>
        </div>
    </section>
    )
}

export default CheckoutSlider;