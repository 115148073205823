import React, { useState, useEffect } from "react";
import axios from "axios";
function Faq() {
    const [faq, setFaq] = useState([]);
    const fetchFaq = async (url) => {
        try {
            const res = await axios.get(url);
            const data = await res.data;
            setFaq(data);
        } catch (error) {
            console.log('faq error', +error);
        }
    }
    useEffect(() => {
        fetchFaq('https://breaddough.kdassociate.us/api/faq');
    }, [])
    return (
        <section id="faq" className="about faq">
            <div className="container">

                <div className="section-header">
                    <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" />
                    <span>FAQ</span>
                    <h2>Frequently Asked Questions</h2>
                </div>
                <div className="row align-items-center flex-row-reverse">
                    <div className="col-lg-6 col-12">
                        <div className="faq-list">
                            <ul>

                                <li>

                                    <i className="fa fa-question-circle icon-help" aria-hidden="true" />{" "}
                                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
                                        What makes your products different?{" "}
                                        <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true" />
                                        <i className="fa fa-sm fa-angle-up icon-close" />
                                    </a>
                                    <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Well, you might have read once or twice that we are a family-owned bakery, and that fact alone sets us apart from most other bakeries. This improves the taste and quality of the products we make. Our desserts are not made in factories like those you might purchase from some grocery store bakeries. We never freeze our cakes, though that too is a common practice.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <i className="fa fa-question-circle icon-help" aria-hidden="true" />{" "}
                                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
                                        I just want a single serving. Can I come in and purchase a small dessert? {" "}
                                        <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true" />
                                        <i className="fa fa-sm fa-angle-up icon-close" />
                                    </a>
                                    <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Yes! Come into our bakery shop where we offer an array of single-serving items all day. We are accessible during business hours, and you do not need to make an appointment in order to enjoy our food. You are welcome to visit our bakery anytime during our business hours.
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <i className="fa fa-question-circle icon-help" aria-hidden="true" />{" "}
                                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-1" className="collapsed">
                                        Are you a full-service bakery, or do you only make cakes?{" "}
                                        <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true" />
                                        <i className="fa fa-sm fa-angle-up icon-close" />
                                    </a>
                                    <div id="faq-list-1" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            We are a full-service bakery. We make bread, afghani-styled naan, cookies (with and without nuts), cakes, pastries
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <i className="fa fa-question-circle icon-help" aria-hidden="true" />{" "}
                                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">
                                        How do I place an order?

                                        {" "}
                                        <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true" />
                                        <i className="fa fa-sm fa-angle-up icon-close" />
                                    </a>
                                    <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            You may place an order through our website; by calling us at 585 404 0072; or by visiting our bakery.                                 </p>
                                    </div>
                                </li>

                                <li>
                                    <i className="fa fa-question-circle icon-help" aria-hidden="true" />{" "}
                                    <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" className="collapsed">
                                        Where can I find your pricing?{" "}
                                        <i className="fa fa-sm fa-angle-down icon-show" aria-hidden="true" />
                                        <i className="fa fa-sm fa-angle-up icon-close" />
                                    </a>
                                    <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
                                        <p>
                                            Please call or visit our website for pricing. Pricing varies depending on the design elements, size and flavour.

                                        </p>
                                    </div>
                                </li>
                            </ul>
                        </div>


                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="about-thumb">
                            <img src="https://breaddough.kdassociate.us/public/upload/profile/1699874168boran%20banjan.jpg" alt="about-food" />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default Faq;