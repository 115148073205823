import { Helmet } from 'react-helmet';

function Title({meta_title,meta_keyword,meta_description}){
    return(
   <>     
    <Helmet>       
        <title>{meta_title}</title>
        <meta name='description' content={meta_description} />
        <meta name="keywords" content={meta_keyword}></meta>       
       
    </Helmet>
    </>
    )
}

export default Title;