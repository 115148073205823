import {useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
function SingleBlog(){
    const {id} = useParams();
    const [singleBlog,setBlog] = useState({});
    const fetchBlogDetails = async(url) =>{
        
        try{
          const res =  await axios.get(url);
          const blogData = await res.data;
          setBlog(blogData);
          console.log(blogData);
        }catch(error){
           console.log("Blog error",+error);
        }
    }
    
    const {post_title,post_slug,post_short_desc,post_date,publisher_name,banner_image,banner_tag,description} = singleBlog;
    useEffect(()=>{
        fetchBlogDetails(`https://breaddough.kdassociate.us/api/postdetails/${id}`);
    },[])
   
    return(
<>
       <Helmet>       
        <title>{post_title}</title>
        <meta name='description' content={post_title}/>
        <meta name="keywords" content={post_title}></meta>       
        </Helmet>
<section class="page-header style-2">
            <div class="container">
                <div class="page-title text-center">
                    <h3>{post_title}</h3>
                    <ul class="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>Blog single</li>
                    </ul>
                </div>
            </div>
</section>
<div class="blog-section blog-page blog-single padding-tb">
            <div class="container">
                <div class="section-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-lg-12 col-12">
                            <article>
                                <div class="post-item">
                                    <div class="post-inner">
                                        <div class="post-thumb">
                                            <img src={banner_image} alt={banner_tag}/>
                                        </div>
                                        <div class="post-content">
                                            <h4>{post_title}</h4>
                                            <div class="meta-post">
                                                <ul>
                                                    <li>
                                                        <i class="icofont-calendar"></i>
                                                        <a href="#" class="date">{post_date}</a>
                                                    </li>
                                                    <li>
                                                        <i class="icofont-ui-user"></i>
                                                        <a href="#" class="admin">{publisher_name}</a>
                                                    </li>
                                                    
                                                </ul>
                                            </div>

                                            <div dangerouslySetInnerHTML={{__html:post_short_desc}}/>
                                            <div dangerouslySetInnerHTML={{__html:description}}/>
                                            
                                        </div>
                                    </div>
                                </div>

                            

                                

                                

                                
                            </article>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</>
    )
}
export default SingleBlog;