import {useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
function SingleShoap({handleClick}){
    const {id} = useParams();
    const [singleProduct,setProduct] = useState({});
    const {title,product_price,amount,photo,cname,sname,description,long_desc} = singleProduct;
    const fetchProductDetails = async(url) =>{
        
        try{
          const res =  await axios.get(url);
          const blogData = await res.data;
          setProduct(blogData);
        }catch(error){
           console.log("Blog error",+error);
        }
    }
    
    useEffect(()=>{
        fetchProductDetails(`https://breaddough.kdassociate.us/api/productdetails/${id}`);
    },[])
    return(
        <>
         <Helmet>       
        <title>{title}</title>
        <meta name='description' content={title}/>
        <meta name="keywords" content={title}></meta>       
        </Helmet>
          <section class="page-header style-2">
            <div class="container">
                <div class="page-title text-center">
                    <h3>{title}</h3>
                    <ul class="breadcrumb">
                        <li><Link to="/">Home</Link></li>
                        <li>{title}</li>
                    </ul>
                </div>
            </div>
        </section>
        <div class="shop-page single padding-tb pb-0">
            <div class="container">
                <div class="section-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-12">
                            <article>
                                <div class="shop-single">
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 col-12">
                                            <div class="swiper-container gallery-top">
                                                <div class="swiper-wrapper">
                                                    <div class="swiper-slide">
                                                        <div class="shop-item">
                                                            <div class="shop-thumb">
                                                                <img src={photo} alt={title}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div class="col-md-8 col-12">
                                            <div class="shop-single-content">  
                                            <h4>{title}</h4>                                              
                                                <div class="desc">
                                                    <div dangerouslySetInnerHTML={{__html:description}}/>
                                                   
                                                </div>
                                            </div>

                                            <div class="popular-chef-widget">
                                                <div class="food-quyality">
                                                  
                                                    <div class="section-wrapper">
                                                        <h4>${product_price}/ pic.</h4>
                        
                                                        <a href="javascript:void(0)" class="food-btn style-2" onClick={() => handleClick(singleProduct,product_price)}><span>Add to Cart</span></a>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </article>
                        </div>
                     
                    </div>
                </div>
            </div>
        </div>


       <div class="review single padding-tb">
            <div class="container">
                <div class="section-wrapper">
                    <div class="related">
                        <ul class="tab-bar">
                           
                            <li class="tablinks"  id="defaultOpen" onclick="openCity(event, 'two')">
                                <span>Description</span>
                            </li>
                        </ul>
                      

                        <div id="two" class="tabcontent">
                            <div class="Description">
                                <div dangerouslySetInnerHTML={{__html:long_desc}} />
                            </div>
                        </div>                 

                        
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default SingleShoap;