import react, { useState, useEffect } from "react";

function Banner() {
    const [slider, setSlider] = useState([]);
    useEffect(() => {
        const fetchSlider = async () => {
            try {
                const response = await fetch("https://breaddough.kdassociate.us/api/slider")
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setSlider(data)

            } catch (error) {
                console.error(error);
            }
        }
        fetchSlider();
    }, [])
    return (
        <>
            <section className="banner-area bg_size" style={{ backgroundImage: "url(assets/images/banner/banermain.jpg)" }}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="banner-content text-center">
                        <span>Welcome to <br></br>Breaddough Hot & Fresh</span>
                        <h4 className="bnr-p">Indulge in Emotions, Taste the Love – Breaddough, Where Every Treat is a Symphony of Feelings in Henrietta!</h4>
                        <div className="banner-btn-group">
                            <a class="food-btn style-2" href="/shoap"><span>Order Now</span></a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="product style-2 padding-tb bg-fa b2">
                <div className="section-header2">
                    {/* <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" /> */}
                    {/* <span>Where Tradition Meets Freshness</span> */}
                    <h2>Savor the True  <br></br>  Essence of  Authentic  <br></br> Afghan Baking
                    </h2>
                </div>
                <div className="section-header">
                    {/* <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" /> */}
                    <span>WELCOME TO</span>
                    <h2> Breaddough  Hot & Fresh</h2>
                    <span>FEEL AT HOME</span>
                </div>

                <div id="team-section" className="inner">

                    <div className="team-content">
                        <div className="t-box1">
                            <img src="assets/images/Icon1/Order Now.png" />
                            <h3>ORDER NOW</h3>
                        </div>
                        <div className="t-box2">

                            <img src="assets/images/Icon1/Menu.png" />
                            <h3>MENU</h3>
                        </div>
                        <div className="t-box3">
                            <img src="assets/images/Icon1/Location.png" />
                            <h3>LOCATIONS</h3>
                        </div>
                        <div className="t-box4">
                            <img src="assets/images/Icon1/Join Us.png" />
                            <h3>JOIN US</h3>
                        </div>
                        <div className="t-box5">
                            <img src="assets/images/Icon1/Contact.png" />
                            <h3>CONTACT US</h3>
                        </div>
                    </div>
                </div>


            </section >

        </>
    )
}
export default Banner;