import { useContext,useEffect } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../context/homecontext";
import Title from "../../pages/Title";
const p_slug = 'about-us';
function About({status}){
    const {isLoading,about,page,fetchPage} = useContext(AppContext);
    const {title,sub_title,description,photo,photo_tag} = about;
    const {short_description,long_description,meta_title,meta_keyword,meta_description} = page;
    useEffect(()=>{
        fetchPage(`https://breaddough.kdassociate.us/api/pagelist/${p_slug}`);
    },[]); 
     if(isLoading) <div>Loading.........</div>
    return(
      <>
     <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description}/> 
    {status !== "home" &&
      <section class="page-header style-2">
        <div class="container">
            <div class="page-title text-center">
                <h3>{title}</h3>
                <ul class="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li>{title}</li>
                </ul>
            </div>
        </div>
    </section>  
    }
        <section className="about padding-tb">
        <div className="container">
            <div className="row align-items-center flex-row-reverse">
                <div className="col-lg-6 col-12">
                    <div className="about-thumb">
                        <img src={photo} alt={photo_tag}/>
                    </div>
                </div>
                <div className="col-lg-6 col-12">
                    <div className="about-content">
                        <div className="section-header">
                            <span>{title}</span>
                            <h3>{sub_title}</h3>
                        </div>
                        <div className="section-wrapper">
                            <div dangerouslySetInnerHTML={{__html:description}}/>
                            {status !== "home" &&
                            <>
                              <div dangerouslySetInnerHTML={{__html:short_description}}/>
                              <div dangerouslySetInnerHTML={{__html:long_description}}/>
                            </>
                            }
                        </div>

                    </div>
                    <a href="#" class="food-btn style-2"><span>Read More</span></a>
                </div>

               
            </div>
        </div>
    </section>
    <section
        className="product bg_size p-rel padding-tb"

      >
        <div className="overlay" />
        <div className="container">
          <div className="section-header">
            <img
              src="assets/images/header/sc-img.png"
              alt="sc-img"
              className="header-img"
            />
            <span>Shop By Category</span>
            <h2>
              Indulge in the rich flavors of
              <br /> our Afghani Taste
            </h2>
          </div>
          <div className="section-wrapper pdcc">
            <div className="row">

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <a href="#">
                  <img src="assets/images/c1.jpg" alt="#" />

                  </a>
                    
                  </div>
                  <div className="product-content sp1">
                    <p>
                    <a href="">Restaurant</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div> 
           
              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <a href="#">
                  <img src="assets/images/c2.jpg" alt="#" />

                  </a>
                    
                  </div>
                  <div className="product-content sp1">
                    <p>
                    <a href="">Bakery</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div> 

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                  <a href="#">
                  <img src="assets/images/c3.jpg" alt="#" />

                  </a>
                    
                  </div>
                  <div className="product-content sp1">
                    <p>
                    <a href="">Beverages</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </section>
    </>  
    )
}

export default About;