function ThankYou({orderid}){
    return(
        <section class="coming-soon d-flex align-items-center">
        <div class="container">
            <div class="row flex-row-reverse align-items-center">
                <div class="col-lg-6 col-12">
                    <div class="coming-thumb">
                        <img src="assets/images/coming-soon/coming-soon.png" alt="coming-thumb"/>
                    </div>
                </div>
                <div class="col-lg-6 col-12">
                    <div class="coming-area">
                        <div class="section-header">
                            <h2>Your Order is Placed Successfully.</h2>
                            <p>Integer at lorem eget diam facilisis laci and ides massa Nulas ornare facilisis exam non porttitor.</p>
                        </div>
                        <div class="section-wrapper">
                            <h3 style={{"margin": "20px 0"}}>Your Order Id is {orderid}</h3>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
export default ThankYou;