import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import About from "./About";
import ShaopByCategory from "../shoap/ShoapByCategory";
import Tab from "./Tab";
import Testimonial from "./Testimonial";
import Faq from "./Faq";
import BookTable from "./BookTable";
import Blog from "./Blog";
import Title from "../../pages/Title";
import { AppContext } from "../../context/homecontext";
const p_slug = 'home';
function Home({ handleClick }) {
  const { page, fetchPage, category, products } = useContext(AppContext);
  const { meta_title, meta_keyword, meta_description } = page;
  const comboproduct = products.filter((abc) => {
    return abc.offer_type > 0;
  })
  useEffect(() => {
    fetchPage(`https://breaddough.kdassociate.us/api/pagelist/${p_slug}`);
  }, [])
  return (
    <>
      <Title meta_title={meta_title} meta_keyword={meta_keyword} meta_description={meta_description} />
      <Banner />

      <section className="about padding-tb bg_size" style={{ backgroundImage: "url(assets/images/bghome.png)" }}>
        <div className="container">
          <div className="row align-items-center flex-row-reverse">

            <div className="col-lg-6 col-12">
              <div className="about-content" style={{ backgroundImage: "linear-gradient(180deg, #1f1410 85%, #081F2E3D 150%)" }}>
                <div className="section-header">
                  <span>EXPLORE OUR</span>
                  <h3>ALL-GOOD MENU</h3>
                </div>
                <div className="section-wrapper">
                  Welcome to 'Breaddough Hot & Fresh,' where we have been proudly serving the New York Community for over four years. Our journey began with a deep-rooted passion for sharing the authentic flavors of Afghanistan with our beloved customers.
                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>ORDER NOW</span></a>
                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>BROWSE MENU</span></a>
                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>ORDER CATERING</span></a>
                </div>
              </div>

            </div>
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                <img className="ab" src="assets/images/bghome1.png" alt="" />
              </div>
            </div>


          </div>
        </div>
      </section>


      <section className="about padding-tb bg_size" style={{ backgroundImage: "url(assets/images/banner/types1.jpg)" }}>
        <div className="container">
          <div className="row align-items-center flex-row-reverse">
            <div className="col-lg-6 col-12">
              <div className="about-thumb">
                {/* <img className="ab" src="assets/images/ab.jpg" alt="" /> */}
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="about-content" style={{ backgroundImage: "linear-gradient(rgb(0 0 0) 0%, rgba(8, 31, 46, 0.24) 58%)" }}>
                <div className="section-header">
                  <span>About Breaddough Hot & Fresh</span>
                  <h3>A World of Fast Food Choices
                  </h3>
                </div>
                <div className="section-wrapper">
                  Welcome to 'Breaddough Hot & Fresh,' where we have been proudly serving the New York Community for over four years. Our journey began with a deep-rooted passion for sharing the authentic flavors of Afghanistan with our beloved customers.


                </div>

                <div className="section-wrapper">
                  Our team brings their culinary expertise to every dish we serve. We take immense pride in our commitment to providing freshly baked sweet and savory delights, ensuring that each bite captures the essence of Afghan tradition.


                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>ORDER NOW</span></a>
                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>BROWSE MENU</span></a>
                </div>
                <div>
                  <a href="about-us" class="food-btn2"><span>ORDER CATERING</span></a>
                </div>

              </div>
            </div>


          </div>
        </div>
      </section>


      <section className="product bg_size p-rel padding-tb">
        <div className="overlay" />
        <div className="container">
          <div className="section-header">
            <img
              src="assets/images/header/sc-img.png"
              alt="sc-img"
              className="header-img"
            />
            <span>Shop By Category</span>
            <h2>
              Indulge in the rich flavors of
              <br /> our Afghani Taste
            </h2>
          </div>
          <div className="section-wrapper pdcc">
            <div className="row">

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                    <a href="#">
                      <img src="assets/images/c1.jpg" alt="#" />

                    </a>

                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="">Restaurant</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                    <a href="#">
                      <img src="assets/images/c2.jpg" alt="#" />

                    </a>

                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="">Bakery</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div>

              <div className="col-md-4 ">
                <div className="product-item">
                  <div className="product-thumb">
                    <a href="#">
                      <img src="assets/images/c3.jpg" alt="#" />

                    </a>

                  </div>
                  <div className="product-content sp1">
                    <p>
                      <a href="">Beverages</a>
                    </p>

                    <a href="" className="cat-lk">Order Now &gt;&gt;&gt;</a>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Tab category={category} products={products} handleClick={handleClick} />
      <section className="featured-item">

        <div className="container pf-mt" >
          <div className="section-header">
            <img src="assets/images/header/sc-img.png" alt="sc-img" className="header-img" />
            <span>Foods Item</span>
            <h2>Today's Featured Item</h2>
          </div>
          <div className="row">

            {comboproduct.map((data) => {
              let url = 'https://breaddough.kdassociate.us/public/upload/profile/1699873334jalebi.jpg';
              if (data.photo != null) {
                url = data.photo;
              }
              return (
                <div class="col-md-6">
                  <div className="card bgcd  mt-4">
                    <img src={url} className="card-img-top"
                      alt="..." />
                    <div className="card-body">
                      <div className="text-section">
                        <h5 className="card-title fw-bold">{data.title}</h5>
                        <div>${data.product_price}</div>

                      </div>


                      <div className="cta-section">
                        <p className="card-text">

                        </p>
                        <Link to={"/shoaps/" + data.product_code} className="btn btn-dark">
                          <span>Order Now</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <Testimonial />
      <Faq />
      <Blog />
    </>
  )
}
export default Home;